import React, { useState } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { handleAsyncAutoCompleteInputChange } from './common';
import { MultiSelectOption, MultiSelectOptions } from '../state/searchVessel';

interface Props {
  stateSetter: (name: string, value: any) => void;
  value: MultiSelectOptions | MultiSelectOption | undefined;
  name: string;
  dbFieldName?: string;
  dbTableName?: string;
  isMulti?: boolean;
  isCreatable?: boolean;
  isDisabled?: any;
}

const FOSBSMultiSelectAsync = (props: Props) => {
  const isMulti = props.isMulti !== false;
  const isCreatable = props.isCreatable === true;
  const dbTableName =
    props.dbTableName === undefined ? 'vessels' : props.dbTableName;

  const [inputValue, setInputValue] = useState('');

  const promiseOptions = async (inputValue: any) => {
    return new Promise((resolve, reject) => {
      handleAsyncAutoCompleteInputChange(
        dbTableName as string,
        props.dbFieldName as string,
        inputValue,
        true,
        (newOpts: any) => {
          return resolve(
            newOpts.map((el: any) => {
              return { label: el, value: el };
            })
          );
        },
        () => {}
      );
    });
  };

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);
    return inputValue;
  };

  const style1 = {
    control: (base: any, state: any) => {
      return {
        ...base,
        border: '1px solid #ced4da',
        borderRadius: '0px',
        outline: '0',
        transition:
          'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
        borderColor: state.isFocused ? '#ced4da' : '#ced4da',
        boxShadow: state.isFocused
          ? '0 0 0 0.2rem rgba(227, 223, 217, 0.90);'
          : '0 !important',
        '&:hover': { borderColor: '#ced4da' }, // border style on hover
      };
    },
  };

  if (isCreatable) {
    return (
      <AsyncCreatableSelect
        isMulti={isMulti}
        isDisabled={props.isDisabled}
        cacheOptions
        defaultOptions
        loadOptions={promiseOptions as any}
        onChange={(vals) => {
          props.stateSetter(props.name, vals);
        }}
        value={props.value}
        placeholder='Type at least 3 characters'
        styles={style1}
      />
    );
  }

  return (
    <AsyncSelect
      isMulti={isMulti}
      isDisabled={props.isDisabled}
      cacheOptions
      defaultOptions
      loadOptions={promiseOptions as any}
      onChange={(vals) => {
        props.stateSetter(props.name, vals);
      }}
      value={props.value}
      placeholder='Type at least 3 characters'
      styles={style1}
    />
  );
};

function areEqual(prevProps: Props, nextProps: Props) {
  const equal = prevProps.value === nextProps.value;
  return equal;
}

export default React.memo(FOSBSMultiSelectAsync, areEqual);
